<template lang="pug">
    .contact-page
        v-snackbar(
            top
            dark
            color="primary"
            v-model="openMessage"
            timeout="5000"
        ) Thanks for your letter !
        .banner(:style="{ backgroundImage: `url(${contact})` }")
        //- .banner
            //- img(:src="contactUsPhone" width="100%" height="300")
        section.contact-info.pa-5.px-sm-10.pt-sm-10.pb-sm-14
            .text-center.text-h4.font-weight-bold.mb-16 Contact Details
            h2.mb-5.text-center.text-sm-left BRISK Services Pte Ltd
            v-row
                v-col.px-0.px-sm-10(
                    :cols="12"
                    :md="6"
                )
                    v-timeline(dense)
                        v-timeline-item(
                            v-for="infoLeft in infoLeftList"
                            :key="infoLeft.value"
                            color="blue lighten-2"
                            fill-dot
                        )
                            a.simple(
                                v-if="infoLeft.href"
                                :href="infoLeft.href"
                            ) {{ infoLeft.value }}
                            span(v-else) {{ infoLeft.value }}
                            template(v-slot:icon)
                                v-icon(
                                    small
                                    color="grey lighten-3"
                                ) {{ infoLeft.icon }}
                v-col.px-0.px-sm-10(
                    :cols="12"
                    :md="6"
                )
                    //- h2.mb-5.text-center.text-sm-left
                    v-timeline(dense)
                        v-timeline-item(
                            v-for="infoLeft in infoRightList"
                            :key="infoLeft.value"
                            color="teal lighten-3"
                            fill-dot
                        )
                            a.simple(
                                v-if="infoLeft.href"
                                :href="infoLeft.href"
                            ) {{ infoLeft.value }}
                            span(v-else) {{ infoLeft.value }}
                            template(v-slot:icon)
                                v-icon(
                                    small
                                    color="grey lighten-3"
                                ) {{ infoLeft.icon }}
        section.pa-5.pa-sm-5.brown.lighten-3
            v-row.ma-0.pa-0
                v-col.px-0.px-md-5.py-md-16(
                    :cols="12"
                    :md="6"
                )
                    v-card.pa-5.pa-sm-7.brown.lighten-5
                        .text-center.text-h4.font-weight-bold.mb-5 Contact Us
                        v-form(
                            ref="form"
                            :valid="valid"
                        )
                            div.d-md-flex.justify-between.align-center
                                v-text-field(
                                    label="Name"
                                    placeholder="Full Name"
                                    :rules="[v => !!v || 'please input name']"
                                    v-model="form.name"
                                    clearable
                                    solo
                                )
                                v-text-field.ml-md-3(
                                    label="E-mail"
                                    placeholder="Email Address"
                                    :rules="[v => !!v || 'please input E-mail']"
                                    v-model="form._replyto"
                                    clearable
                                    solo
                                )
                            v-text-field(
                                label="Subject"
                                placeholder="Subject"
                                v-model="form._subject"
                                solo
                            )
                            v-textarea(
                                label="Vessel Name / Company Contact Number Inquiry"
                                placeholder="Message"
                                :rules="[v => !!v || 'please input Vessel Name / Company Contact Number Inquiry']"
                                v-model="form.message"
                                clearable
                                solo
                            )
                        v-col.px-0(:span="12")
                            v-btn(
                                block
                                dark
                                color="brown lighten-2"
                                @click="sendMail"
                            ) Send
                v-col.map.px-0.px-md-10.py-md-16(
                    :cols="12"
                    :md="6"
                )
                    div.map-block
                        div.name-block.orange.darken-3
                            div
                                div BRISK
                        iframe(
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.774469415524!2d103.69628311533356!3d1.3106591990440613!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da1833a3035c25%3A0x5e34413321a7e1be!2sACE%20%40%20Buroh!5e0!3m2!1sen!2stw!4v1631550235328!5m2!1sen!2stw"
                            style="border:0;"
                            allowfullscreen=""
                            loading="lazy"
                        )
</template>

<script>
import contact from "@/assets/contact.jpg";

export default {
    data() {
        return {
            contact,
            valid: true,
            openMessage: false,
            form: {
                name: "",
                _replyto: "",
                _subject: "",
                message: "",
            },
        };
    },
    computed: {
        infoLeftList: {
            get: () => [
                {
                    value: "TEL: +65 6677 1122 , +65 6677 5599",
                    icon: "fa fa-phone",
                    href: "tel:+65 6677 1122",
                },
                {
                    value: "Ops 24/7: +65 9732 9333",
                    icon: "fa fa-mobile",
                    href: "tel:+65 9732 9333",
                },
                {
                    value: "Project: +65 8800 8088",
                    icon: "fa fa-mobile",
                    href: "tel:+65 8800 8088",
                },
                // {
                //     value: "傳真：01-12345678",
                //     icon: "fa fa-fax",
                //     // href: "fax:03-3601223",
                // },
                // {
                //     value: "E-mail：brisk@gmail.com",
                //     icon: "fa fa-envelope",
                //     href: "mailto:brisk@gmail.com",
                // },
                // {
                //     value: "地址：77 Happy Street, Happy Town, Happy District, Happy City",
                //     icon: "fa fa-map-marker-alt",
                // },
            ],
        },
        infoRightList: {
            get: () => [
                // {
                //     value: "電話：00-12345678",
                //     icon: "fa fa-phone",
                //     href: "tel:03-3603832",
                // },
                // {
                //     value: "行動電話(1)：0912-345-678",
                //     icon: "fa fa-mobile",
                //     href: "tel:0912-345-678",
                // },
                // {
                //     value: "行動電話(2)：0912-345-678",
                //     icon: "fa fa-mobile",
                //     href: "tel:0912-345-678",
                // },
                {
                    value: "Fax: +65 6262 1137",
                    icon: "fa fa-fax",
                    // href: "fax:03-3601223",
                },
                {
                    value: "E-mail：sales@brisks.com.sg",
                    icon: "fa fa-envelope",
                    href: "mailto:sales@brisks.com.sg",
                },
                {
                    value: "Address: 2 Buroh Crescent, ACE @ Buroh, #01-16 : Singapore 627546",
                    icon: "fa fa-map-marker-alt",
                },
            ],
        },
    },
    methods: {
        sendMail() {
            if (this.$refs.form.validate()) {
                fetch("https://formspree.io/f/mleazgwv", {
                    method: "post",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(this.form),
                })
                    .then((res) => {
                        return res.json();
                    })
                    .then((data) => {
                        if (data.ok) {
                            this.openMessage = true;
                            this.$refs.form.reset();
                        }
                    })
                    .catch((err) => {
                        console.log("err :>> ", err);
                    });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/mediaQuery.scss';

.contact-info::v-deep {
    .v-timeline-item {
        .v-timeline-item__body {
            display: flex;
            align-self: center;
        }
    }
}
.map {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .map-block {
        position: relative;
        width: 100%;

        iframe {
            width: 100%;
            height: 510px;
            border-radius: 3px 3px;

            @include MQ(mobile) {
                height: 80vw;
            }
        }

        .name-block {
            position: absolute;
            top: 10px;
            right: 10px;
            height: 150px;
            width: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-weight: bold;
            border-radius: 3px 3px;
            box-shadow: 5px 5px 12px #aaa;

            @include MQ(mobile) {
                position: relative;
                top: 0;
                left: 0;
                height: 100px;
                width: 100%;
                font-size: 20px;
                margin-bottom: 20px;
            }
        }
    }
}
a.simple {
    text-decoration: none;
    color: inherit
}

a.simple:hover {
    text-decoration: underline;
    font-weight: bold;
    color: #1976d2;
}
</style>
